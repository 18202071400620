
              @import "@/assets/css/variables.scss";
            






































































































.table-wrapper {
  width: 100%;
  padding: 0px;
  margin-top: 48px;
}
@media (min-width: 550px) {
  .table-wrapper {
    margin-top: 64px;
  }
}
@media (min-width: 768px) {
  .table-wrapper {
    padding: 24px;
  }
}
@media (min-width: 1024px) {
  .table-wrapper {
    padding: 50px;
  }
}
@media (min-width: 1536px) {
  .table-wrapper {
    padding: 60px;
  }
}
